.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 50px; /* Reduce height */
  font-size: smaller; /* Adjust font size */
}
.App-body {
  padding-top: 60px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your main styles file or create a new CSS file */
.card {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  transition: background-color 0.3s, transform 0.3s;
}

.product-card:hover {
  background-color: #f5f5f5; /* Change the background color on hover */
  transform: translateY(-5px); /* Elevate the card on hover */
  background-color: #bfd6da;
}

.card h2 {
  color: #333;
}

.card p {
  color: #666;
}

.card a {
  color: #007bff;
  text-decoration: none;
}
/* card row */

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 10;
}

.product-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
  transition: box-shadow 0.5s ease;
  background-color: #fff;
  margin: 10px; /* Add margin to create gap between cards */
}

.service-kard {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 100px; /* Adjust the padding value as needed */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  /* padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
  transition: box-shadow 0.5s ease;
  background-color: #fff;
  margin: 10px;  */
}

.service-kard:hover {
  /* transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #BFD6DA; */
  background-color: #f5f5f5; /* Change the background color on hover */

  background-color: #bfd6da;
}

.kard a {
  text-decoration: none; /* Remove underline */
  color: #007bff; /* Use the default text color or set it to your desired color */
}
/* Add these styles to your App.css or any other stylesheet */

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 70px;
  bottom: 0;
  width: 100%;

  font-family: sans-serif;
}

/* Optional: Style the text within the footer */
.footer p {
  margin: 0;
  font-size: 14px;

  color: #777777;
  /* Set your desired text color */
}

.footerabout {
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 70px;
  bottom: 0;
  width: 100%;

  font-family: sans-serif;
  margin: 0;
  font-size: 14px;

  color: #777777;
}

.contactfooter {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 70px;
  bottom: 0;
  width: 150%;

  font-family: sans-serif;
  margin: 0;
  font-size: 14px;

  color: #777777;
}
/* App.css */

/* Add styles for additional images */
.additional-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  justify-content: center;
}

.additional-images img {
  width: 150px;
  height: 150px;
  margin: 5px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ddd;
  object-fit: cover;
  /* width: 100%;
  height: 100%;
  border: 1px solid #ddd; 
  border-radius: 10px;
  object-fit: cover; */
}

/* .additional-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

.send-email-button {
  background-color: rgba(0, 0, 255, 0.521); /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 18px;
  transition: background-color 0.3s ease;
}

.send-email-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.title-bold-center {
  font-weight: bold;
  text-align: center;
}
.subtitle {
  text-align: center;
}
.button-center {
  text-align: center;
}
.button-center a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.link-full-width {
  white-space: normal;
}

.my-slider {
  max-width: 800px;
  max-height: 100;
  margin: 0 auto;
  overflow: hidden;
}
.my-slider-item {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.my-slider-item.slick-active {
  opacity: 1;
}

/* LucentChartComponent.css */

.lucent-chart-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.lucent-chart-image {
  width: 20%;
  display: block;
  margin: 0 auto;
}

/* drawer style */

.linkStyle {
  text-decoration: none;
  color: #3e8b94;
  transition: color 0.3s ease, background-color 0.3s ease, font-weight 0.3s ease;
  font-weight: normal;
}

.linkStyle:hover {
  color: red;
}

.subMenuItemStyle {
  font-size: 12px; /* Adjust the font size as needed */
  color: blueviolet;
}

.contactstyle {
  font-family: metropolis;
}

.blog-content {
  display: flex;
}

.content-column {
  flex: 1;

  /* Adjust spacing as needed */
}

.dynamicButton {
  padding: 10px 20px;
  background-color: #3749bb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dynamicButton:hover {
  background-color: #293e8e;
}

.signupButton {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.signupButton:hover {
  background-color: #293e8e;
}

.service-card-container {
  /* justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 10;
}

.Home-vital-card {
  display: inline-block;
  padding: 2%;
  width: 95%;
  height: 95%;
  margin: 5px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8); /* Increased shadow effect */
  /* transition: all 0.1s ease-in-out; */
  opacity: 0.9; /* Increased opacity */
  transition: opacity 0.2s ease-in-out;

  /* Responsiveness using media queries */
  @media (max-width: 768px) {
    width: 80%;
    margin: 5px;
  }
}

/* /// for drawer style  */
/* For medium-sized screens and above (e.g., tablets) */
@media only screen and (min-width: 768px) {
  /* Define styles for the drawer */
}

/* For small-sized screens (e.g., mobile devices) */
@media only screen and (max-width: 767px) {
  /* Define styles to hide the drawer and adjust the navbar */
}







.text-typing p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  overflow: hidden;
  animation: typing 4s steps(22, end) forwards;
 
}

@keyframes typing {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes blink {
  0%,
  100% {
    border-right: 2px solid transparent;
  }

  50% {
    border-right: 2px solid #222;
  }
}


.hero-image img {
  transition: transform 0.9s ease-in-out;
}
.hero-image img:hover {
  transform: scale(1.1);
}
