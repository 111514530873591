.card {
  position: absolute;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Border */
}

.card-content:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.looking-for {
  text-align: center;
}

.looking-for img {
  width: 40%;
}


.Qad-advace-card-32{

   display: flex; /* Use flexbox to center the content vertically and horizontally */
  justify-content: center; /* Center horizontally */
  /* Center vertically */
  width: 49vw;
  height: 12rem;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

.advace-card {
  width: 22vw;
  height: 13rem;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .advace-card {
    width: 90vw; /* Adjust width for smaller screens */
  }
}

@media only screen and (max-width: 480px) {
  .advace-card {
    height: auto; /* Allow height to adjust based on content */
  }
}
